@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700');

@font-face {
  font-family: 'Montserrat';
  src: url("/assets/fonts/montserrat/Montserrat-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'Open Sans';
  src: url("/assets/fonts/Open_Sans/OpenSans-Regular.ttf") format("opentype");
}

html,
body {
  height: 100%;
  width: 100%;
}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {}

h2,
h3 {
  font-family: 'Open Sans', 'sans-serif';
  font-weight: 600;
}

body p {
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 300;
}

.container img {
  width: 100%;
  padding: 5%;
}


.whatisiamtrusted {
  position: relative;
  bottom: 40px;
  right: 40px;
  width: 100%;
}

.validationsystem {
  position: relative;
  bottom: 40px;
  left: 40px;
  width: 100%;
}

.searchbody {
  background: url(/assets/img/search_bg.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.resultbody {
  background: url(/assets/img/result_bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-left: 3px; 
  margin: 0%; 
  font-family: 'Montserrat', 'Open Sans';
}

.white {
  background-color: white;
}

.whitetwo {
  background-color: white;
}

.navi img {
  size: 0%;
  display: flex;
  margin: 1.5rem;
}

.navi li {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

h3 {
  font-weight: 600;
}

.bg1 {
  background: url(/assets/img/bg1.png);
  background-repeat: no-repeat;

}

.bg2 {
  background: url(/assets/img/bg2.png);
  background-repeat: no-repeat;
}

.bg3 {
  background: url(assets/img/bg3.png);
  background-repeat: no-repeat;
}

footer {
  background-color: #285888;
  padding: 1rem 2rem 3rem;
}

.green {
  border-right: 10px solid #537473;
  border-left: 10px solid #537473;
  border-bottom: 10px solid #537473;
  margin: 0 auto;
  padding: 25px;

}


footer p,
footer a,
.socket a {
  color: white;
  text-decoration: none;
}

footer img {
  height: 3.5rem;
  margin: 1.5rem auto;
}

ul.social {
  list-style-type: none;
  display: flex;
  max-width: 16rem;
  margin: 0 auto;
}

ul.social li {
  margin: 0 auto;
}

footer .svg-inline--fa {
  font-size: 2.3rem;
}

footer .svg-inline--fa:hover {
  color: #FCD283;

}

.socket {
  background-color: #2E4769;
}

.btn {
  color: white;
  background-color: #389583;
  outline-color: none;
}

.vertical-center {
  min-height: 50%;
  min-height: 50vh;
  width: 97%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
}

/*---Firefox Bug Fix --*/
.carousel-item {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

/*--- Fixed Background Image --*/
figure {
  position: relative;
  width: 100%;
  height: 60%;
  margin: 0 !important;
}

.fixed-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#fixed {
  background-image: url(/assets/img/mac.png);
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}

/*--- Bootstrap Padding Fix --*/



img {
  display: block;
  max-width: 100%;
  max-height: 100vh;
}

strong {
  font-weight: bold;
}

.breaker {
  background-image: url(/assets/img/breaker.png);
  margin: 0 auto;
}

#link {
  color: #00004e;
  width: 18%;
}

.homedivtext {
  background-image: url(/assets/img/nologo.png);
  padding: 25px;
}
/*
Extra small (xs) devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap

Small (sm) devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

Medium (md) devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

Large (lg) devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

Extra (xl) large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }
*/
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #285888;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #285888;
  border-color: #285888;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.row li {
  float: left;
}

.icon {
  border: 0 none;
  display: inline-block;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}